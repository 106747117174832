<template>
  <div class="wrapper pa-6">
    <h2 v-text="$t('streaming.views')" />
    <v-row class="stats-container justify-end align-center px-6">
      <v-col class="stat">
        <div class="text-h6" v-text="streaming.stats.totalViewers" />
        <div class="text-overline stat__text" v-text="$t('streamingStats.totalViewers')" />
      </v-col>
      <v-col class="stat">
        <div class="text-h6" v-text="streaming.stats.peakViewers" />
        <div class="text-overline stat__text" v-text="$t('streamingStats.peakViewers')" />
      </v-col>
      <v-col class="stat">
        <div class="text-h6" v-text="streaming.stats.averageViewers" />
        <div class="text-overline stat__text" v-text="$t('streamingStats.averageViewers')" />
      </v-col>
    </v-row>
    <div class="mb-10">
      <v-sparkline
        fill
        :gradient=" ['#2acccc']"
        height="80"
        show-labels
        label-size="4"
        padding="8"
        smooth="8"
        :value="streaming.stats.averageViewersEvery5Minutes"
        :labels="labels"
        auto-draw
        color="secondary"
        stroke-linecap="round"
      />
    </div>
    <h2 v-text="$t('streaming.viewerList')" />
    <streaming-stats-viewer-list :streaming="streaming" />
  </div>
</template>

<script>
export default {
  name: 'StreamingStats',
  components: {
    StreamingStatsViewerList: () => import('@/modules/activity/StreamingStatsViewerList'),
  },
  computed: {
    streaming() {
      return this.$store.state.streaming.dbData
    },
    labels({ streaming }) {
      return streaming.stats.averageViewersEvery5Minutes.map((v, i) => `${i * 5}m`)
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  // max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
}

.stats-container {
  max-height: 300px;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: none;
  width: auto;
  padding: 0 20px 30px 20px;
}

.stat__text {
  border-top: 1px solid #2acccc;
  line-height: 26px;
}

// Graph
::v-deep svg g {
  animation: fadeIn 2s ease-in;

  & text:nth-child(even) {
    display: none;
  }
}

::v-deep svg path {
  opacity: 0.2;
}

@keyframes fadeIn {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
</style>
